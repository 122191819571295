/**
 * File generated by js-routes 2.2.4
 * Based on Rails 6.1.7.7 routes of Plato::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/:id/accessibility_mode(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessibility_mode_shared_program_game_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"accessibility_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/challenge/accessibility_mode(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accessibility_mode_shared_program_lesson_challenge_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"accessibility_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/:id/add_class(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_class_admin_subscriber_student_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_class"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/add_collection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_collection_staffer_questions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"add_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/add_material(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_material_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_material"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/add_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_media_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/:id/add_program(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_program_admin_subscriber_teacher_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:id/add_program(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_program_teacher_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/students/:id/add_student(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_student_teacher_student_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_student"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_alert_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_roster_districts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auto_roster_district_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_roster_districts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_auto_roster_districts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/batch_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_batch_update_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cleanup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_cleanup_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cleanup"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cliparts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_clipart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cliparts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cliparts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_cliparts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cliparts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/common_cartridges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_common_cartridge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"common_cartridges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/custom_programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_custom_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"custom_programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instructions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_differentiating_instruction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instructions/:differentiating_instruction_id/differentiating_instruction_images(.:format)
 * @param {any} differentiating_instruction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_differentiating_instruction_differentiating_instruction_images_path = __jsr.r({"differentiating_instruction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[3,"differentiating_instruction_id"],[2,[7,"/"],[2,[6,"differentiating_instruction_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instruction_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_differentiating_instruction_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instruction_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_previews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_email_previews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_previews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/enhancing_learnings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_enhancing_learning_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/games(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_games_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"games"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/glossary_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_glossary_term_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"glossary_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/homes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_homes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"homes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/image_vendors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_image_vendor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"image_vendors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/image_vendors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_image_vendors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"image_vendors"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_images_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invitations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_invitation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invitations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_invitations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/assessments(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/differentiating_instructions(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_differentiating_instructions_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/enhancing_learnings(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_enhancing_learnings_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/lesson_materials(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_lesson_materials_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"lesson_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lesson_module_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_module_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lesson_module_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lesson_module_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_module_sections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lesson_module_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/modules(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_modules_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"modules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/notebook_answer_keys(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_notebook_answer_keys_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"notebook_answer_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/procedures(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_procedures_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"procedures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/science_backgrounds(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_science_backgrounds_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"science_backgrounds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/sections(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_sections_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/slide_shows(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_slide_shows_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/student_handouts(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_lesson_student_handouts_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"student_handouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/maps/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_map_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"maps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/materials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_materials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"materials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/measurement_units/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_measurement_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"measurement_units"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/measurement_units(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_measurement_units_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"measurement_units"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_materials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_model_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_model_materials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_model_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_model_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/net_suite_po(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_net_suite_po_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"net_suite_po"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebook_answer_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notebook_answer_key_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebook_answer_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/:notebook_id/auto_number_questions(.:format)
 * @param {any} notebook_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notebook_auto_number_questions_path = __jsr.r({"notebook_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"notebook_id"],[2,[7,"/"],[2,[6,"auto_number_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_notebooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_procedure_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedure_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_procedure_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedure_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedures/:procedure_id/procedure_images(.:format)
 * @param {any} procedure_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_procedure_procedure_images_path = __jsr.r({"procedure_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"procedure_id"],[2,[7,"/"],[2,[6,"procedure_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/common_cartridges(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_common_cartridges_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"common_cartridges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/custom_programs(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_custom_programs_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"custom_programs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_kit_materials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_kit_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_kit_materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_lessons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/lessons(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/maps(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_maps_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"maps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_indices(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_program_indices_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_indices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_kit_materials(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_program_kit_materials_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_kit_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_lessons(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_resources(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_program_resources_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/qti_packages(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_qti_packages_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"qti_packages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/units(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_program_units_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/qti_packages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_qti_package_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qti_packages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/assessment_questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"assessment_questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reading_games/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reading_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reading_games"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_reference_materials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/safety_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_safety_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"safety_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/safety_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_safety_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"safety_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sandbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sandbox_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sandbox"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/science_backgrounds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_science_background_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"science_backgrounds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_activity_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity/interactions(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_activity_interactions_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[2,[7,"/"],[2,[6,"interactions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity/simulations(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_activity_simulations_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[2,[7,"/"],[2,[6,"simulations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/glossary_terms(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_glossary_terms_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"glossary_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/notebooks(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_notebooks_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"notebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/reading_games(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_reading_games_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"reading_games"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/section_video_urls(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_section_video_urls_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"section_video_urls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/section_video_urls/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_section_video_url_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"section_video_urls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/simulations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_simulation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"simulations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:slide_id/slide_game(.:format)
 * @param {any} slide_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_slide_game_path = __jsr.r({"slide_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"slide_id"],[2,[7,"/"],[2,[6,"slide_game"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:slide_id/slide_statuses/create(.:format)
 * @param {any} slide_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_slide_statuses_path = __jsr.r({"slide_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"slide_id"],[2,[7,"/"],[2,[6,"slide_statuses"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_slide_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/student_handouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_student_handout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"student_handouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/admins/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_admin_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/admins(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_admins_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"admins"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/classes(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_classes_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/coordinators/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_coordinator_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"coordinators"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/coordinators(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_coordinators_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"coordinators"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/course_mapping(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_course_mapping_index_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"course_mapping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/home(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_home_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/import_students(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_import_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"import_students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/import_teachers(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_import_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"import_teachers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/imports(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_imports_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/integrations/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_integration_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/integrations(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_integrations_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"integrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/invitations/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_invitation_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/invitations(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_invitations_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/licenses(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_licenses_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"licenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/professional_developments(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_professional_developments_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"professional_developments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/program_mappings(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_program_mappings_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mappings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/programs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriber_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriber_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/roster_events(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_roster_events_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"roster_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/seat_pools/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_seat_pool_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"seat_pools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriber_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_stats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriber_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_student_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/subscriptions(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_teacher_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teacher_invitations(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_teacher_invitations_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teacher_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sysadmins/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sysadmin_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sysadmins"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sysadmins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sysadmins_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sysadmins"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_system_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_system_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/allowed_iframe_domains/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_allowed_iframe_domain_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"allowed_iframe_domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/allowed_iframe_domains(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_allowed_iframe_domains_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"allowed_iframe_domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_apps/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_lti_app_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_apps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_apps(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_lti_apps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_apps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_lti_key_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_lti_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/roster_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_roster_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"roster_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/saml_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_saml_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"saml_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/saml_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_tech_integrations_saml_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"saml_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/themes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_themes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"themes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/toggle_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_toggle_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"toggle_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/toggle_image_layers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_toggle_image_layer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"toggle_image_layers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/toggle_image_layers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_toggle_image_layers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"toggle_image_layers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/toggle_images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_toggle_images_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"toggle_images"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfer_teachers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_transfer_teachers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfer_teachers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/translations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_translations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"translations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/trial_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_trial_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trial_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/unit_contents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unit_contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/unit_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unit_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/unit_sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unit_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/sections(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_sections_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/slide_shows(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_slide_shows_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"slide_shows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/unit_project_materials(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_unit_project_materials_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"unit_project_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/unit_resources(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_unit_unit_resources_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"unit_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/affiliate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const affiliate_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"affiliate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/shopping_lists/all_items(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_items_teacher_program_shopping_lists_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"shopping_lists"],[2,[7,"/"],[2,[6,"all_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/procedures/all_pdf(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_pdf_teacher_lesson_procedures_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[6,"all_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/correlations/all(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_shared_program_correlations_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"correlations"],[2,[7,"/"],[2,[6,"all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /assign-assess-grading(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_assess_grading_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assign-assess-grading"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/assessments/assign(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_staffer_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clever/assignments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignments_clever_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clever"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /canvas/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authorize_canvas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"canvas"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /google_classroom/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authorize_google_classroom_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_classroom"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/auto_number_questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_number_questions_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"auto_number_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/autocomplete_alert_message_title(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_alert_message_title_admin_alert_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[6,"autocomplete_alert_message_title"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders/autocomplete_purchase_order_admin_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_purchase_order_admin_email_admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[2,[7,"/"],[2,[6,"autocomplete_purchase_order_admin_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders/autocomplete_purchase_order_number(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_purchase_order_number_admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[2,[7,"/"],[2,[6,"autocomplete_purchase_order_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/subscriptions/autocomplete_purchase_order_number(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_purchase_order_number_admin_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"autocomplete_purchase_order_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders/autocomplete_purchase_order_order_number(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_purchase_order_order_number_admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[2,[7,"/"],[2,[6,"autocomplete_purchase_order_order_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/subscriptions/autocomplete_purchase_order_order_number(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_purchase_order_order_number_admin_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"autocomplete_purchase_order_order_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search/autocomplete_staffer_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_staffer_email_admin_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"autocomplete_staffer_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/autocomplete_staffer_email(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_staffer_email_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"autocomplete_staffer_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/autocomplete_staffer_email(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_staffer_email_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"autocomplete_staffer_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search/autocomplete_staffer_last_name(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_staffer_last_name_admin_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"autocomplete_staffer_last_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/autocomplete_staffer_last_name(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_staffer_last_name_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"autocomplete_staffer_last_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/autocomplete_student_last_name(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_student_last_name_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"autocomplete_student_last_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/autocomplete_student_last_name(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_student_last_name_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"autocomplete_student_last_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/autocomplete_student_username(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_student_username_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"autocomplete_student_username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/autocomplete_student_username(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_student_username_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"autocomplete_student_username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/autocomplete_subscriber_city(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_subscriber_city_admin_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"autocomplete_subscriber_city"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/autocomplete_subscriber_name(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_subscriber_name_admin_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"autocomplete_subscriber_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/masquerade/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_staffer_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/masquerade/back(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const back_student_masquerade_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[6,"back"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/scores/:id/benchmarks(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const benchmarks_teacher_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"benchmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/common_cartridges/:id/build_cartridge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_cartridge_admin_common_cartridge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"common_cartridges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_cartridge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/build_guide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_guide_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_guide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qti_packages/:id/build_package(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_package_admin_qti_package_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qti_packages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_package"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/build_pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_pdf_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/build_pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_pdf_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/build_pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const build_pdf_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"build_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/builder(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const builder_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"builder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id/builder(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const builder_shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"builder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /canvas/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const callback_canvas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"canvas"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /google_classroom/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const callback_google_classroom_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_classroom"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_staffer_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_student_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lti_launch/canvas_json(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const canvas_json_lti_launch_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_launch"],[2,[7,"/"],[2,[6,"canvas_json"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/scores/:id/change_max_notebook_points(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_max_notebook_points_teacher_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_max_notebook_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/change_template(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const change_template_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"change_template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /check_sign_on(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_sign_on_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"check_sign_on"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/units/:id/checkpoints_pdf(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkpoints_pdf_shared_program_unit_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"checkpoints_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /google_classroom/classrooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const classrooms_google_classroom_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_classroom"],[2,[7,"/"],[2,[6,"classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lms_course/client_classrooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_classrooms_lms_course_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lms_course"],[2,[7,"/"],[2,[6,"client_classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lms_course/client_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_sections_lms_course_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lms_course"],[2,[7,"/"],[2,[6,"client_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/simulations/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_admin_simulation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"simulations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/clone_existing_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_existing_image_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone_existing_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id/clone_new_section(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_new_section_admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone_new_section"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/clone_new_slide_show(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_new_slide_show_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone_new_slide_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/clone_new_slide_show(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_new_slide_show_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone_new_slide_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/decks/:id/clone(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_shared_program_lesson_deck_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"decks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/clone(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/decks/clone_tci_deck(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_tci_deck_shared_program_lesson_decks_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"decks"],[2,[7,"/"],[2,[6,"clone_tci_deck"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/:id/comment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_shared_question_answer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"comment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/confirm_seat_release(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_seat_release_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"confirm_seat_release"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/settings/:id/contact_admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_admin_teacher_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/admins/contact_admins(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_admins_admin_subscriber_admins_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"admins"],[2,[7,"/"],[2,[6,"contact_admins"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cooperative-tolerant-classroom(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cooperative_tolerant_classroom_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cooperative-tolerant-classroom"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/copy_to(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_to_staffer_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"copy_to"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ngss_standards/correlations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const correlations_ngss_standards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ngss_standards"],[2,[7,"/"],[2,[6,"correlations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/units/:id/correlations(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const correlations_shared_program_unit_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"correlations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/modules/cover(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cover_admin_lesson_modules_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[6,"cover"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/cover(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cover_shared_program_lesson_slide_shows_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"cover"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/create_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_group_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /create_lti_assignment(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_lti_assignment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_lti_assignment"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/reading_games/create_or_redirect(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_or_redirect_admin_section_reading_games_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"reading_games"],[2,[7,"/"],[2,[6,"create_or_redirect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/create_page_break(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_page_break_staffer_question_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[6,"create_page_break"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/deep_copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deep_copy_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deep_copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /lti_launch/deep_linking(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deep_linking_lti_launch_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_launch"],[2,[7,"/"],[2,[6,"deep_linking"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/delete_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_image_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id/delete_interaction_image(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_interaction_image_admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_interaction_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/delete_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_media_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:id/delete_video(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_video_admin_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_video"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_staffer_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_student_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/seat_pools/:id/disable(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_admin_subscriber_seat_pool_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"seat_pools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/settings/dismiss_system_message(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dismiss_system_message_shared_program_settings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"dismiss_system_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/assessments/district(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const district_staffer_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"district"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /transfer_trials/transfer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const do_transfer_trials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_trials"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_roster_districts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_auto_roster_district_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cliparts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_clipart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cliparts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/common_cartridges/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_common_cartridge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"common_cartridges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instructions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_differentiating_instruction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instruction_images/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_differentiating_instruction_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instruction_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/enhancing_learnings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_enhancing_learning_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/games/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/glossary_terms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_glossary_term_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"glossary_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/image_vendors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_image_vendor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"image_vendors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/edit(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/maps/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_map_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"maps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_model_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebook_answer_keys/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_notebook_answer_key_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebook_answer_keys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedures/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_procedure_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedure_images/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_procedure_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedure_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_kit_materials/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_program_kit_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_kit_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_lessons/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_program_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_program_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qti_packages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_qti_package_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qti_packages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reading_games/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_reading_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reading_games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/science_backgrounds/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_science_background_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"science_backgrounds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/section_video_urls/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_section_video_url_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"section_video_urls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/simulations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_simulation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"simulations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_slide_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:slide_id/slide_game/edit(.:format)
 * @param {any} slide_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_slide_slide_game_path = __jsr.r({"slide_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"slide_id"],[2,[7,"/"],[2,[6,"slide_game"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/student_handouts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_student_handout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"student_handouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/seat_pools/:id/edit(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subscriber_seat_pool_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"seat_pools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/:id/edit(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subscriber_student_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/:id/edit(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_subscriber_teacher_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_messages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_system_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_apps/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tech_integrations_lti_app_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_apps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_keys/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tech_integrations_lti_key_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_keys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/saml_settings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_tech_integrations_saml_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"saml_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/unit_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_unit_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unit_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/unit_sections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_unit_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unit_sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/factoid(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_factoid_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"factoid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/image(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_image_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/:id/edit_notebook_params(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_notebook_params_admin_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_notebook_params"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/:id/edit_questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_questions_admin_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/sections(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sections_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/decks/:id/edit(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_shared_program_lesson_deck_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"decks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/edit(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/materials/:id/edit(.:format)
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_lesson_material_path = __jsr.r({"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_question_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staffer_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_student_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/assessments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_teacher_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_teacher_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/messages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_teacher_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/staffer_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_teacher_staffer_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"staffer_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /terms_of_service/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_terms_of_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"terms_of_service"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /elementary-science(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const elementary_science_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"elementary-science"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /elementary-social-studies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const elementary_social_studies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"elementary-social-studies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /elementary-social-studies-national(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const elementary_social_studies_national_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"elementary-social-studies-national"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/exclude_from_trial(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const exclude_from_trial_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"exclude_from_trial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/pacings/export_csv(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_csv_teacher_program_pacings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"pacings"],[2,[7,"/"],[2,[6,"export_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/scores/:id/export_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_csv_teacher_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /external(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const external_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"external"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/investigations/fast_track_pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fast_track_pdf_shared_program_lesson_investigations_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"fast_track_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/filter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_admin_alert_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[6,"filter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/:program_id/question_banks/filter(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_staffer_program_question_banks_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"question_banks"],[2,[7,"/"],[2,[6,"filter"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/images/find_dup_images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_dup_images_admin_images_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[6,"find_dup_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/find_dup_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const find_dup_images_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"find_dup_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/notebooks/for_first_section(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const for_first_section_admin_section_notebooks_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"for_first_section"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/for_lesson(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const for_lesson_shared_program_games_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[6,"for_lesson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/games/:student_id(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const games_shared_question_answers_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"student_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cleanup/generate_csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_csv_admin_cleanup_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cleanup"],[2,[7,"/"],[2,[6,"generate_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:id/generate_planning_pdf(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_planning_pdf_shared_program_lesson_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_planning_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/generate_txt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_txt_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/generate_txt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_txt_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id/generate_txt(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_txt_admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/toggle_images/:id/get_image_layers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_image_layers_shared_toggle_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"toggle_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_image_layers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/get_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_images_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/materials/get_lesson_materials(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_lesson_materials_staffer_lesson_materials_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[6,"get_lesson_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/get_lessons(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_lessons_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/get_locations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_locations_admin_alert_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[6,"get_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_apps/get_lti_apps(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_lti_apps_admin_tech_integrations_lti_apps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_apps"],[2,[7,"/"],[2,[6,"get_lti_apps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/get_materials(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/materials/get_materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_admin_materials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/get_materials(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/get_materials(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:id/get_materials(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_admin_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/units/:unit_id/materials/get_materials(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_materials_staffer_unit_materials_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[6,"get_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/get_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_media_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_keys/get_new_consumer_secret(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_new_consumer_secret_admin_tech_integrations_lti_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_keys"],[2,[7,"/"],[2,[6,"get_new_consumer_secret"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/safety_notes/get_safety_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_safety_notes_admin_safety_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"safety_notes"],[2,[7,"/"],[2,[6,"get_safety_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/sections/get_sections(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_sections_admin_lesson_sections_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"get_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/get_sections(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_sections_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/get_slide_images(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_slide_images_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_slide_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/get_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_states_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/get_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_states_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/get_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_states_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/:id/get_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_states_admin_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id/get_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_states_admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/toggle_images/get_toggle_images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_toggle_images_shared_toggle_images_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"toggle_images"],[2,[7,"/"],[2,[6,"get_toggle_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /google-and-tci(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_and_tci_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google-and-tci"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/by_question/:question_id(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grade_by_question_shared_question_answers_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"by_question"],[2,[7,"/"],[2,[3,"question_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/by_student/:student_id(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grade_by_student_shared_question_answers_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"by_student"],[2,[7,"/"],[2,[3,"student_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/exercise_results/:id/grade(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grade_shared_exercise_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"grade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/grade(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grade_shared_question_answers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"grade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /graphiql
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphiql_rails_path = __jsr.r({}, [2,[7,"/"],[6,"graphiql"]]);

/**
 * Generates rails route to
 * /graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphql_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/:id/group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_staffer_question_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/group(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_staffer_question_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[6,"group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /high-school-social-studies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const high_school_social_studies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"high-school-social-studies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:slide_id/slide_game/html(.:format)
 * @param {any} slide_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const html_admin_slide_slide_game_path = __jsr.r({"slide_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"slide_id"],[2,[7,"/"],[2,[6,"slide_game"],[2,[7,"/"],[2,[6,"html"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/insert(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const insert_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"insert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/invite(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/common_cartridges/:id/is_updated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const is_updated_admin_common_cartridge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"common_cartridges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"is_updated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/qti_packages/:id/is_updated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const is_updated_admin_qti_package_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"qti_packages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"is_updated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /jwks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const jwks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"jwks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /lti_launch/launch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const launch_lti_launch_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_launch"],[2,[7,"/"],[2,[6,"launch"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffers/launch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const launch_staffer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"launch"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/investigations/lesson_guide_with_extensions_pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lesson_guide_with_extensions_pdf_shared_program_lesson_investigations_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"lesson_guide_with_extensions_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/lesson_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lesson_index_admin_notebooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"lesson_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/media_resources/lesson_lazy_load(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lesson_lazy_load_admin_program_media_resources_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"media_resources"],[2,[7,"/"],[2,[6,"lesson_lazy_load"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/assessments/library(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const library_shared_program_assessments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"library"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/library(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const library_shared_program_games_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[6,"library"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /lti_launch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lti_launch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_launch"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /lti_show(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lti_show_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_show"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/manage_programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_programs_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"manage_programs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /middle-school-science(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const middle_school_science_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"middle-school-science"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /middle-school-social-studies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const middle_school_social_studies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"middle-school-social-studies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /navigation-TCI(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const navigation_TCI_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"navigation-TCI"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /navigation-tci(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const navigation_tci_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"navigation-tci"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/sections/:id/nb_questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nb_questions_shared_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"nb_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_alert_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_roster_districts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_auto_roster_district_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/batch_update/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_batch_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"batch_update"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cliparts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_clipart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cliparts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/differentiating_instructions/:differentiating_instruction_id/differentiating_instruction_images/new(.:format)
 * @param {any} differentiating_instruction_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_differentiating_instruction_differentiating_instruction_image_path = __jsr.r({"differentiating_instruction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[3,"differentiating_instruction_id"],[2,[7,"/"],[2,[6,"differentiating_instruction_images"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/image_vendors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_image_vendor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"image_vendors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invitations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/assessments/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_assessment_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/differentiating_instructions/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_differentiating_instruction_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/enhancing_learnings/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_enhancing_learning_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/modules/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_module_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/notebook_answer_keys/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_notebook_answer_key_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"notebook_answer_keys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/procedures/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_procedure_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/science_backgrounds/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_science_background_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"science_backgrounds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/sections/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_section_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/slide_shows/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_slide_show_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/student_handouts/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_lesson_student_handout_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"student_handouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_resources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_model_resource_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/net_suite_po/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_net_suite_po_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"net_suite_po"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_notebook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/procedures/:procedure_id/procedure_images/new(.:format)
 * @param {any} procedure_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_procedure_procedure_image_path = __jsr.r({"procedure_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"procedure_id"],[2,[7,"/"],[2,[6,"procedure_images"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/common_cartridges/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_common_cartridge_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"common_cartridges"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/lessons/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_lesson_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/maps/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_map_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"maps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_kit_materials/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_program_kit_material_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_kit_materials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_lessons/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_program_lesson_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_lessons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_resources/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_program_resource_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/qti_packages/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_qti_package_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"qti_packages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/units/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_program_unit_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_reference_material_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity/interactions/new(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_section_activity_interaction_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity/simulations/new(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_section_activity_simulation_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[2,[7,"/"],[2,[6,"simulations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/glossary_terms/new(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_section_glossary_term_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"glossary_terms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/notebooks/new(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_section_notebook_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/section_video_urls/new(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_section_section_video_url_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"section_video_urls"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_resources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_slide_resource_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:slide_show_id/slides/new(.:format)
 * @param {any} slide_show_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_slide_show_slide_path = __jsr.r({"slide_show_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"slide_show_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:slide_id/slide_game/new(.:format)
 * @param {any} slide_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_slide_slide_game_path = __jsr.r({"slide_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"slide_id"],[2,[7,"/"],[2,[6,"slide_game"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subscriber_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/invitations/new(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subscriber_invitation_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/new(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subscriber_student_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/new(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_subscriber_teacher_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_messages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_system_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_apps/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tech_integrations_lti_app_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_apps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/lti_keys/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tech_integrations_lti_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"lti_keys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/saml_settings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_tech_integrations_saml_setting_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"saml_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/sections/new(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_unit_section_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/slide_shows/new(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_unit_slide_show_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/unit_resources/new(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_unit_unit_resource_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"unit_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_shared_slide_show_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/materials/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_lesson_material_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_program_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_question_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_question_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staffer_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/classes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_student_classroom_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_student_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_student_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/new(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_classroom_student_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/assessments/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_lesson_assessment_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/staffer_files/new(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_lesson_staffer_file_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"staffer_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/classes/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_program_classroom_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/import_students/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_program_import_student_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"import_students"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/messages/new(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_teacher_program_message_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /oauth/clever(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauth_clever_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"clever"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /lti_launch/openid(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const openid_lti_launch_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lti_launch"],[2,[7,"/"],[2,[6,"openid"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/units/:id/overview_pdf(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const overview_pdf_shared_program_unit_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"overview_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/tech_integrations/saml_settings/parse_metadata(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parse_metadata_admin_tech_integrations_saml_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tech_integrations"],[2,[7,"/"],[2,[6,"saml_settings"],[2,[7,"/"],[2,[6,"parse_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/professional_developments/pd_certificate_pdf(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pd_certificate_pdf_teacher_program_professional_developments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"professional_developments"],[2,[7,"/"],[2,[6,"pd_certificate_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/print/pdf(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_admin_lesson_print_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"print"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:id/pdf(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_shared_program_lesson_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/decks/pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_shared_program_lesson_decks_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"decks"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:assessment_id/print/pdf(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_staffer_assessment_print_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"print"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/differentiating_instructions/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_teacher_differentiating_instruction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/enhancing_learnings/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_teacher_enhancing_learning_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/procedures/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_teacher_procedure_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/program_resources/:id/pdf(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_teacher_program_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"program_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/modules/:id/pdf_updated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_updated_admin_module_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf_updated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/pdf_updated(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_updated_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf_updated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/pending_invitations(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_invitations_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"pending_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pghero
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_path = __jsr.r({}, [2,[7,"/"],[6,"pghero"]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_space_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space/:relation(.:format)
 * @param {any} relation
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_relation_space_path = __jsr.r({"database":{},"relation":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[2,[7,"/"],[2,[3,"relation"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/index_bloat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_index_bloat_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"index_bloat"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/live_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_live_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"live_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries/:query_hash(.:format)
 * @param {any} query_hash
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_show_query_path = __jsr.r({"database":{},"query_hash":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[2,[7,"/"],[2,[3,"query_hash"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/cpu_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_cpu_usage_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"cpu_usage"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connection_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connection_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connection_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/replication_lag_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_replication_lag_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"replication_lag_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/load_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_load_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"load_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/free_space_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_free_space_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"free_space_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/explain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_explain_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"explain"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/tune(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_tune_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"tune"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connections_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connections"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_maintenance_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_long_running_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_long_running_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_long_running_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_all_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/enable_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_enable_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"enable_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/reset_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_reset_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"reset_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero/(:database)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_root_path = __jsr.r({"database":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[2,[1,[3,"database"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/investigations/planning_pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const planning_pdf_shared_program_lesson_investigations_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"planning_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_path = __jsr.r({}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]]);

/**
 * Generates rails route to
 * /api/v2/me(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_me_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"me"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/convert(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_convert_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"convert"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/assessments/:id/randomize(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_randomize_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"randomize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assessments/filter_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_filter_categories_assessments_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"filter_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assessments/gradable_by_staffer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_gradable_by_staffer_assessments_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"gradable_by_staffer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assessments_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/completed_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_completed_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"completed_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/settings_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_settings_data_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"settings_data"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/reading_games/:id/reset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_reading_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reading_games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reading_games(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reading_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reading_games"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/reading_games/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reading_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reading_games"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/:assignment_id/assignments_models(.:format)
 * @param {any} assignment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignment_assignments_models_path = __jsr.r({"assignment_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"assignment_id"],[2,[7,"/"],[2,[6,"assignments_models"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/:assignment_id/assignments_student_seats(.:format)
 * @param {any} assignment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignment_assignments_student_seats_path = __jsr.r({"assignment_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"assignment_id"],[2,[7,"/"],[2,[6,"assignments_student_seats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/:assignment_id/assignments_assignee_models(.:format)
 * @param {any} assignment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignment_assignments_assignee_models_path = __jsr.r({"assignment_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"assignment_id"],[2,[7,"/"],[2,[6,"assignments_assignee_models"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/:assignment_id/classrooms(.:format)
 * @param {any} assignment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignment_classrooms_path = __jsr.r({"assignment_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"assignment_id"],[2,[7,"/"],[2,[6,"classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/sync_grades(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_sync_grades_assignments_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[6,"sync_grades"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignments_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_assignable_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_assignable_types_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_assignable_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_unit_project_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_unit_project_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_unit_project_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_investigation_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_investigation_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_investigation_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_reading_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_reading_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_reading_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_simple_reading_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_simple_reading_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_simple_reading_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_slide_show_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_slide_show_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_slide_show_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_game_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_game_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_game_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_assessment_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_assessment_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_assessment_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_reference_material_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_reference_material_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_reference_material_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/get_initial_assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_initial_assignables_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"get_initial_assignables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignables_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_new_assignable_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_edit_assignable_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/assignables/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assignable_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"assignables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/auto_roster_syncs/:id/get_sync_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_sync_results_auto_roster_sync_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_sync_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/auto_roster_syncs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_auto_roster_syncs_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"auto_roster_syncs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/auto_roster_syncs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_auto_roster_sync_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_share_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:id/manage_students(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_manage_students_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"manage_students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/link_program(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_link_program_classrooms_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[6,"link_program"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:classroom_id/staffers(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_staffers_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"staffers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:classroom_id/student_seats(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_student_seats_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"student_seats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:classroom_id/students(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classrooms_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/classrooms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classroom_lessons/get_classroom_lesson(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_classroom_lesson_classroom_lessons_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classroom_lessons"],[2,[7,"/"],[2,[6,"get_classroom_lesson"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/classroom_lessons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_lessons_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classroom_lessons"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/classroom_lessons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_classroom_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"classroom_lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/decks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_decks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"decks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/decks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_deck_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"decks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/feature_flags/launchdarkly_webhook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_launchdarkly_webhook_feature_flags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"feature_flags"],[2,[7,"/"],[2,[6,"launchdarkly_webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/feature_flags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_feature_flags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"feature_flags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/feature_flags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_new_feature_flag_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"feature_flags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/feature_flags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_edit_feature_flag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"feature_flags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/feature_flags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_feature_flag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"feature_flags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:id/questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_questions_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/filter_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_filter_categories_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[6,"filter_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/state_capitals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_state_capitals_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[6,"state_capitals"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/lesson_games(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_lesson_games_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[6,"lesson_games"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/program_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_access_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[6,"program_access"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/create_vocab_game(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_create_vocab_game_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[6,"create_vocab_game"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:game_id/question_groups(.:format)
 * @param {any} game_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_question_groups_path = __jsr.r({"game_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"game_id"],[2,[7,"/"],[2,[6,"question_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:game_id/question_attachments(.:format)
 * @param {any} game_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_question_attachments_path = __jsr.r({"game_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"game_id"],[2,[7,"/"],[2,[6,"question_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:game_id/exercise_results(.:format)
 * @param {any} game_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_exercise_results_path = __jsr.r({"game_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"game_id"],[2,[7,"/"],[2,[6,"exercise_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:game_id/exercise_results/:id(.:format)
 * @param {any} game_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_exercise_result_path = __jsr.r({"game_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"game_id"],[2,[7,"/"],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/games(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_games_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/games/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/glossary_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_glossary_terms_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"glossary_terms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/glossary_terms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_glossary_term_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"glossary_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/glossary_term_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_glossary_term_models_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"glossary_term_models"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/glossary_term_models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_glossary_term_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"glossary_term_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/header_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_header_notifications_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"header_notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/highlights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_highlights_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"highlights"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/highlights/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_highlight_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"highlights"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/lms_sync_events/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_lms_sync_events_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"lms_sync_events"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/lms_sync_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_lms_sync_events_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"lms_sync_events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/mapped_programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_mapped_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"mapped_programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/reset_video_activity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_video_activity_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"reset_video_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/reset_professional_development(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_professional_development_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"reset_professional_development"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/reset_incomplete_professional_development(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_incomplete_professional_development_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"reset_incomplete_professional_development"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/get_video_activity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_video_activity_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"get_video_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/unit_projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_unit_projects_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"unit_projects"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/scorable_notebooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_scorable_notebooks_notebooks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"scorable_notebooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id/nb_questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_nb_questions_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"nb_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id/turn_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_turn_in_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"turn_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id/turn_in_unit_notebook(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_turn_in_unit_notebook_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"turn_in_unit_notebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id/staffer_turn_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_turn_in_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"staffer_turn_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_copy_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/notebooks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:id/same_series(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_same_series_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"same_series"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:id/copy_resources(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_copy_resources_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:id/copy_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_copy_status_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/programs_by_license(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_programs_by_license_programs_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[6,"programs_by_license"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:program_id/lessons/:id/lesson_overview(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_lesson_overview_index_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"lesson_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:program_id/lessons(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:program_id/units(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_units_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_programs_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_lessons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_purchase_orders_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/questions/:question_id/question_attachments(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_question_attachments_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/questions/:question_id/question_attachments/:id(.:format)
 * @param {any} question_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_question_attachment_path = __jsr.r({"question_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/questions/:question_id/question_answers(.:format)
 * @param {any} question_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_question_answers_path = __jsr.r({"question_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_answers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/questions/:question_id/question_answers/:id(.:format)
 * @param {any} question_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_question_answer_path = __jsr.r({"question_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"questions"],[2,[7,"/"],[2,[3,"question_id"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_groups/:question_group_id/questions(.:format)
 * @param {any} question_group_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_group_questions_path = __jsr.r({"question_group_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"question_group_id"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_groups/:question_group_id/question_attachments(.:format)
 * @param {any} question_group_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_group_question_attachments_path = __jsr.r({"question_group_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"question_group_id"],[2,[7,"/"],[2,[6,"question_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/staffer_biographies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_biographies_reference_materials_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[6,"staffer_biographies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/staffer_reference_materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_reference_materials_reference_materials_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[6,"staffer_reference_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_reference_materials_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/:id/get_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_media_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"get_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/:id/add_media(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_add_media_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_media"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/:id/exercise_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_exercise_results_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"exercise_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reference_materials_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_new_reference_material_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_edit_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reference_materials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/roster_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_roster_events_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"roster_events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/roster_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_roster_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"roster_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/sections/:id/turn_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_turn_in_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"turn_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slack_commands/merge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_merge_slack_commands_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slack_commands"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slack_commands/sprint_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_sprint_update_slack_commands_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slack_commands"],[2,[7,"/"],[2,[6,"sprint_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/lesson_guide_shares(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_lesson_guide_shares_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"lesson_guide_shares"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_index_pdf_uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_index_pdf_upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_index_pdf_uploads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/seat_pools/manage_programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_manage_programs_seat_pools_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"seat_pools"],[2,[7,"/"],[2,[6,"manage_programs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/seat_pools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_seat_pools_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"seat_pools"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/seat_pools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_seat_pool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"seat_pools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/:id/program_access(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_access_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"program_access"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/:id/turn_in(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_turn_in_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"turn_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/:id/tags(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_tags_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/:id/professional_development_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_professional_development_results_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"professional_development_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/reset_slide_show(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_slide_show_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"reset_slide_show"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/professional_development(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_professional_development_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"professional_development"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/course_badges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_course_badges_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"course_badges"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/slide_show_questions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_show_questions_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"slide_show_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/staffer_slideshows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_slideshows_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"staffer_slideshows"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_shows_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_shows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects/:id/remove_duplicates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_remove_duplicates_slide_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_duplicates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects/:slide_object_id/three_d_models(.:format)
 * @param {any} slide_object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_object_three_d_models_path = __jsr.r({"slide_object_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[2,[7,"/"],[2,[3,"slide_object_id"],[2,[7,"/"],[2,[6,"three_d_models"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/three_d_models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_three_d_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"three_d_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects/:slide_object_id/panoramic_images(.:format)
 * @param {any} slide_object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_object_panoramic_images_path = __jsr.r({"slide_object_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[2,[7,"/"],[2,[3,"slide_object_id"],[2,[7,"/"],[2,[6,"panoramic_images"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/panoramic_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_panoramic_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"panoramic_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects/:slide_object_id/model_interactive_maps(.:format)
 * @param {any} slide_object_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_object_model_interactive_maps_path = __jsr.r({"slide_object_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[2,[7,"/"],[2,[3,"slide_object_id"],[2,[7,"/"],[2,[6,"model_interactive_maps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/model_interactive_maps/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_model_interactive_map_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"model_interactive_maps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_objects_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_objects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_object_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_objects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/three_d_models/:three_d_model_id/textures(.:format)
 * @param {any} three_d_model_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_three_d_model_textures_path = __jsr.r({"three_d_model_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"three_d_models"],[2,[7,"/"],[2,[3,"three_d_model_id"],[2,[7,"/"],[2,[6,"textures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/textures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_texture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"textures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_statuses_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_statuses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_program_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_program_settings_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_program_settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_program_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_program_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_program_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/transfer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_transfer_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/import_staffer_program_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_import_staffer_program_data_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"import_staffer_program_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/update_with_password(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_update_with_password_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_with_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/archive_sysadmin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_archive_sysadmin_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive_sysadmin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/generate_otp(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_generate_otp_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_otp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/validate_otp(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_validate_otp_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate_otp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/disable_mfa(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_disable_mfa_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable_mfa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id/reset_mfa(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_mfa_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_mfa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:staffer_id/assessments(.:format)
 * @param {any} staffer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_assessments_path = __jsr.r({"staffer_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"staffer_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:staffer_id/assignments(.:format)
 * @param {any} staffer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_assignments_path = __jsr.r({"staffer_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"staffer_id"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:staffer_id/classrooms(.:format)
 * @param {any} staffer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_classrooms_path = __jsr.r({"staffer_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"staffer_id"],[2,[7,"/"],[2,[6,"classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/status/set_return_to(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_set_return_to_status_index_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"status"],[2,[7,"/"],[2,[6,"set_return_to"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_status_index_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/assignments/student_index(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_index_student_assignments_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[6,"student_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/assignments(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_assignments_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/assignments/new(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_new_student_assignment_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/assignments/:id/edit(.:format)
 * @param {any} student_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_edit_student_assignment_path = __jsr.r({"student_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/assignments/:id(.:format)
 * @param {any} student_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_assignment_path = __jsr.r({"student_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:student_id/change_password(.:format)
 * @param {any} student_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_change_password_path = __jsr.r({"student_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"student_id"],[2,[7,"/"],[2,[6,"change_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/students/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscribers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:id/check_provider_connection(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_check_provider_connection_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_provider_connection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:id/check_lms_connection(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_check_lms_connection_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_lms_connection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:id/programs_by_license(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_programs_by_license_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"programs_by_license"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_syncs/run_manual_sync(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_run_manual_sync_subscriber_auto_roster_syncs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[6,"run_manual_sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_syncs/run_sync(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_run_sync_subscriber_auto_roster_syncs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[6,"run_sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_syncs/run_program_assignment_sync(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_run_program_assignment_sync_subscriber_auto_roster_syncs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[6,"run_program_assignment_sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_syncs/:id/perform_now(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_perform_now_subscriber_auto_roster_sync_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_syncs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"perform_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_syncs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_auto_roster_syncs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_syncs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_districts(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_auto_roster_districts_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/auto_roster_districts/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_auto_roster_district_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"auto_roster_districts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriber_oauth_credentials(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_subscriber_oauth_credentials_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriber_oauth_credentials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriber_oauth_credentials/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_subscriber_oauth_credential_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriber_oauth_credentials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/lti_keys(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_lti_keys_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"lti_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/lti_keys/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_lti_key_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"lti_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/saml_settings(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_saml_settings_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"saml_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/saml_settings/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_saml_setting_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"saml_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/lti_key(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_lti_key_index_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"lti_key"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/licenses/:id/remove_demo_license(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_remove_demo_license_subscriber_license_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_demo_license"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/licenses(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_licenses_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"licenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/mapped_programs/course_mapping(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_course_mapping_subscriber_mapped_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"mapped_programs"],[2,[7,"/"],[2,[6,"course_mapping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/mapped_programs/batch_update(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_batch_update_subscriber_mapped_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"mapped_programs"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/mapped_programs/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_mapped_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"mapped_programs"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/mapped_programs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_mapped_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"mapped_programs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/program_mappings/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_program_mappings_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mappings"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/program_mappings/bulk_action(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_action_subscriber_program_mappings_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mappings"],[2,[7,"/"],[2,[6,"bulk_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/program_mappings(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_program_mappings_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mappings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/program_mappings/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_program_mapping_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mappings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/program_mapping_versions(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_program_mapping_versions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"program_mapping_versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/students/delete_all(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_delete_all_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"delete_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/students/bulk_delete(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_delete_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"bulk_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/students(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/students/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_student_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/roster_events(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_roster_events_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"roster_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/classrooms/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_classrooms_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"classrooms"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/classrooms(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_classrooms_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/classrooms/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_classroom_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"classrooms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/bulk_remove_program(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_remove_program_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"bulk_remove_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/bulk_set_roster_exclusion(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_set_roster_exclusion_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"bulk_set_roster_exclusion"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/bulk_delete(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_delete_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"bulk_delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/bulk_lock(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_lock_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"bulk_lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/pd_results(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_pd_results_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"pd_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers/search_pd_results(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_pd_results_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"search_pd_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/staffers(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_staffers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"staffers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/students/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/purchase_orders(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_purchase_orders_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/programs/add_program(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_add_program_subscriber_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"add_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/programs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_programs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/sso_models(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_sso_models_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"sso_models"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/sso_models/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_sso_model_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"sso_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/teacher_batch_update_csvs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_teacher_batch_update_csvs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teacher_batch_update_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/teacher_import_csvs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_teacher_import_csvs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teacher_import_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/teacher_invitations(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_teacher_invitations_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teacher_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/student_batch_update_csvs/:id/perform_now(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_perform_now_subscriber_student_batch_update_csv_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"student_batch_update_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"perform_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/student_batch_update_csvs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_student_batch_update_csvs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"student_batch_update_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/student_import_csvs(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_student_import_csvs_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"student_import_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/create_return(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_create_return_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"create_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/create_complementary_order(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_create_complementary_order_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"create_complementary_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/:id/transfer(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_transfer_subscriber_subscription_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/:id/exchange_program(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_exchange_program_subscriber_subscription_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"exchange_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/subscriptions/:id(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_subscription_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/terms/bulk_update(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_bulk_update_subscriber_terms_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"terms"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:subscriber_id/terms(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_terms_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"terms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscribers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/subscribers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_subscriber_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tags/program_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_tags_tags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"tags"],[2,[7,"/"],[2,[6,"program_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tags/student_benchmarks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_benchmarks_tags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"tags"],[2,[7,"/"],[2,[6,"student_benchmarks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tags/student_benchmarks_for_tag(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_benchmarks_for_tag_tags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"tags"],[2,[7,"/"],[2,[6,"student_benchmarks_for_tag"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tags/clear_lesson_cache(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_clear_lesson_cache_tags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"tags"],[2,[7,"/"],[2,[6,"clear_lesson_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_tags_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/teacher_batch_update_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_teacher_batch_update_csvs_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"teacher_batch_update_csvs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/teacher_import_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_teacher_import_csvs_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"teacher_import_csvs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/teacher_invitations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_teacher_invitations_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"teacher_invitations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_publish_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos/:id/unpublish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_unpublish_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unpublish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_videos_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos/filter_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_filter_categories_videos_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[2,[7,"/"],[2,[6,"filter_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_videos_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/model_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_model_select_index_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"model_select"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/model_videos/create_unique(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_create_unique_model_videos_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"model_videos"],[2,[7,"/"],[2,[6,"create_unique"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/model_videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_model_videos_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"model_videos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/model_videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_model_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"model_videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/staffer_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_staffer_notifications_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"staffer_notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/student_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_student_notifications_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"student_notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/user_canvas_ids/disconnect_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_disconnect_account_user_canvas_ids_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"user_canvas_ids"],[2,[7,"/"],[2,[6,"disconnect_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/user_google_ids/disconnect_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_disconnect_account_user_google_ids_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"user_google_ids"],[2,[7,"/"],[2,[6,"disconnect_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/:exercise_result_id/question_answers(.:format)
 * @param {any} exercise_result_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_exercise_result_question_answers_path = __jsr.r({"exercise_result_id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"exercise_result_id"],[2,[7,"/"],[2,[6,"question_answers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/:id/reset_for_question_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_for_question_group_exercise_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_for_question_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/:id/reset_reading_games(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reset_reading_games_exercise_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_reading_games"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/:id/print_assessment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_print_assessment_exercise_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_assessment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/game_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_game_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"game_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/reading_game_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reading_game_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"reading_game_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/notebook_lesson_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_notebook_lesson_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"notebook_lesson_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/notebook_section_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_notebook_section_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"notebook_section_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/assessment_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assessment_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"assessment_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/video_activity_result(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_video_activity_result_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"video_activity_result"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/unit_project_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_unit_project_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"unit_project_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/slideshow_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slideshow_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"slideshow_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/biography_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_biography_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"biography_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/reference_material_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reference_material_results_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[6,"reference_material_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_exercise_results_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/exercise_results/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_exercise_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"exercise_results"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/assessment_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_assessment_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"assessment_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/biography_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_biography_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"biography_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/reference_material_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_reference_material_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"reference_material_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/video_activity_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_video_activity_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"video_activity_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/all_section_notebook_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_all_section_notebook_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"all_section_notebook_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/slideshow_progress(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slideshow_progress_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"slideshow_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/all_progress_for_slideshow_for_classrooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_all_progress_for_slideshow_for_classrooms_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[6,"all_progress_for_slideshow_for_classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_answers_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/question_answers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_question_answer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"question_answers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/images/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_search_images_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"images"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/images/describe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_describe_images_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"images"],[2,[7,"/"],[2,[6,"describe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_images_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/image_models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_image_models_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"image_models"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/image_models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_image_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"image_models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/html_elements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_html_elements_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"html_elements"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/html_elements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_html_element_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"html_elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/correlations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_correlations_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"correlations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/csp_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_csp_reports_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"csp_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/units(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_units_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"units"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/units/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_unit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/glossary_terms/get_glossary_terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_glossary_terms_glossary_terms_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"glossary_terms"],[2,[7,"/"],[2,[6,"get_glossary_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/conditions/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_copy_condition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"conditions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_conditions_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/conditions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_condition_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"conditions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_licenses/get_states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_get_states_program_licenses_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_licenses"],[2,[7,"/"],[2,[6,"get_states"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_licenses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_licenses_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_licenses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_licenses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_new_program_license_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_licenses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_licenses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_edit_program_license_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_licenses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_licenses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_license_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_licenses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_license_states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_license_states_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_license_states"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/program_license_states/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_program_license_state_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"program_license_states"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slides/:id/copy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_copy_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slides/:id/delete_background_audio(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_delete_background_audio_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_background_audio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slides(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slides_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slides"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slides/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cliparts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_cliparts_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"cliparts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/audio_descriptions/update_order_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_update_order_status_audio_descriptions_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"audio_descriptions"],[2,[7,"/"],[2,[6,"update_order_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_sound_clips(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_sound_clips_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_sound_clips"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/slide_sound_clips/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_slide_sound_clip_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"slide_sound_clips"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/themes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_themes_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"themes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/style_sheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_style_sheets_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"style_sheets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/style_sheets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_style_sheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"style_sheets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_settings_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v2/captions/place_bulk_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plato_api_place_bulk_order_captions_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[6,"v2"]]]],[7,"/"]],[2,[6,"captions"],[2,[7,"/"],[2,[6,"place_bulk_order"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/classes/popup_viewed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const popup_viewed_student_classrooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[6,"popup_viewed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/popup_viewed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const popup_viewed_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"popup_viewed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/post_scores(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_scores_shared_program_lesson_assessment_scores_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[6,"post_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id/present_mode(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const present_mode_shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"present_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/present(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const present_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/units/:id/present(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const present_shared_program_unit_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id/present(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const present_shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"present"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_indices/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_program_index_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_indices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/sections/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_shared_sections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/my_notes/print(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const print_shared_program_lesson_my_notes_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"my_notes"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/messages/program_classrooms(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const program_classrooms_teacher_program_messages_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"program_classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/program_guide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const program_guide_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"program_guide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/settings/program_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const program_settings_teacher_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"program_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/:id/promote_to_coordinator(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promote_to_coordinator_admin_subscriber_teacher_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"promote_to_coordinator"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/publish(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_admin_alert_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/publish_collection(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_collection_staffer_questions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"publish_collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/games/:id/publish_toggle(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_toggle_admin_game_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish_toggle"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/publish_toggle(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_toggle_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"publish_toggle"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id/questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const questions_shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search/release_seat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_seat_admin_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"release_seat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/release_seat(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const release_seat_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"release_seat"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/:id/remove(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_admin_notebook_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/remove_lesson(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_lesson_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_lesson"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:id/remove_program(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_program_teacher_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_program"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:id/remove_staffer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_staffer_teacher_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_staffer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/program_lessons/:id/remove_unit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_unit_admin_program_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"program_lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_unit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/assessments/:assessment_id/assessment_questions/reorder(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_assessment_questions_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"assessment_questions"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cliparts/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_cliparts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cliparts"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/differentiating_instructions/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_differentiating_instructions_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/enhancing_learnings/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_enhancing_learnings_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/modules/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_modules_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"modules"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/procedures/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_procedures_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/sections/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_sections_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/student_handouts/reorder(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_lesson_student_handouts_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"student_handouts"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/model_resources/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_model_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"model_resources"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_notebooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/lessons/reorder(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/maps/reorder(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_program_maps_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"maps"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/program_resources/reorder(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_program_program_resources_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_resources"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/slide_shows/reorder(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_program_slide_shows_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/units/reorder(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_program_units_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/activity/reorder(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_section_activity_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"activity"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:section_id/notebooks/reorder(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_section_notebooks_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:slide_show_id/slides/reorder(.:format)
 * @param {any} slide_show_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_slide_show_slides_path = __jsr.r({"slide_show_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"slide_show_id"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/toggle_images/:id/reorder(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_toggle_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"toggle_images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/sections/reorder(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_unit_sections_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/unit_contents/reorder(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_admin_unit_unit_contents_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"unit_contents"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:lesson_id/challenge/reorder_questions(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_questions_admin_lesson_challenge_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"reorder_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/reorder(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_shared_program_lesson_slide_shows_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_staffer_question_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/reorder(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_staffer_questions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invitations/:id/resend(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_admin_invitation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/invitations/:id/resend(.:format)
 * @param {any} subscriber_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_admin_subscriber_invitation_path = __jsr.r({"subscriber_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders/resend_parent_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_parent_email_admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[2,[7,"/"],[2,[6,"resend_parent_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/seat_pools/reset_assigned_counts(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_assigned_counts_admin_subscriber_seat_pools_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"seat_pools"],[2,[7,"/"],[2,[6,"reset_assigned_counts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/reset_classroom_scores(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_classroom_scores_shared_program_lesson_assessment_scores_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[6,"reset_classroom_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/challenge_scores/reset_classroom_scores(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_classroom_scores_teacher_classroom_challenge_scores_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"challenge_scores"],[2,[7,"/"],[2,[6,"reset_classroom_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:id/reset_exercise_results(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_exercise_results_staffer_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_exercise_results"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/reset_signup_code(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_signup_code_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"reset_signup_code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /saml/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saml_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"saml"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /saml(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const saml_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"saml"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /teacher/settings/scoring_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const scoring_settings_teacher_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"scoring_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invitations/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_invitations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_search_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/search_purchase_orders/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_search_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"search_purchase_orders"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscriber_reports/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_subscriber_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscriber_reports"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/subscriptions/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_subscriber_subscriptions_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/search(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfer_teachers/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_transfer_teachers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfer_teachers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trial_reports/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_trial_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trial_reports"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:id/search(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_shared_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signup_codes/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_signup_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup_codes"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/search(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /transfer_customers/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_transfer_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_customers"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_parents/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_transfer_parents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_parents"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_trials/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_transfer_trials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_trials"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /searchjoy
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_path = __jsr.r({}, [2,[7,"/"],[6,"searchjoy"]]);

/**
 * Generates rails route to
 * /searchjoy/searches/overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_overview_searches_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /searchjoy/searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_searches_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /searchjoy/searches/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_searches_recent_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[2,[7,"/"],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /searchjoy/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]]);

/**
 * Generates rails route to
 * /admin/cleanup/select_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_message_admin_cleanup_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cleanup"],[2,[7,"/"],[2,[6,"select_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/challenge_scores/send_challenge_scores(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_challenge_scores_teacher_classroom_challenge_scores_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"challenge_scores"],[2,[7,"/"],[2,[6,"send_challenge_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/:id/send_game_scores(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_game_scores_shared_program_game_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_game_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/classes/set_as_default_classroom(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_as_default_classroom_student_classrooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[6,"set_as_default_classroom"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/set_display_type(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_display_type_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_display_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/alert_messages/:id/set_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_states_admin_alert_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"alert_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/set_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_states_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:id/set_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_states_admin_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reference_materials/:id/set_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_states_admin_reference_material_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sections/:id/set_states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_states_admin_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/programs/:program_id/lessons/set_title_number(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_title_number_admin_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[6,"set_title_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/share(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_staffer_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_teacher_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/games(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_games_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"games"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/interactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/assessments(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_assessments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/assignments(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_assignments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:id/correlations/:correlation_id/correlations(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {any} correlation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_correlation_correlations_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"correlation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"correlations"],[2,[7,"/"],[2,[3,"correlation_id"],[2,[7,"/"],[2,[6,"correlations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:id/correlations(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_correlations_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"correlations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/dashboard(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_dashboard_index_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/:id(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_game_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/glossaries(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_glossaries_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"glossaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:id(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessments/:id(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_assessment_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/:id(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_assessment_score_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessments(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_assessments_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/challenge(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_challenge_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/challenge/answers(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_challenge_answers_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge"],[2,[7,"/"],[2,[6,"answers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/decks(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_decks_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"decks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/glossary_cards(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_glossary_cards_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"glossary_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/my_notes(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_my_notes_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"my_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lesson_slide_shows_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_lessons_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/maps/:id(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_map_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"maps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/maps(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_maps_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"maps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/program_indices(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_program_indices_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_indices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/reference_materials/:id(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_reference_material_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"reference_materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/reference_materials(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_reference_materials_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"reference_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/units/:id(.:format)
 * @param {any} program_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_unit_path = __jsr.r({"program_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/videos(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_program_videos_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/question_answers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_question_answer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"question_answers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/sections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_section_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/shared_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_shared_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"shared_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/slides/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slide_shows(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_slide_shows_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slide_shows"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/assessments/shared(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_staffer_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"shared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/toggle_images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_toggle_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"toggle_images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signup_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup_codes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/slideshow_slides(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const slideshow_slides_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"slideshow_slides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/assessments(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/materials/:id(.:format)
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_lesson_material_path = __jsr.r({"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"materials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/materials(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_lesson_materials_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/masquerade/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_masquerade_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/:program_id/kit_materials(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_program_kit_materials_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"kit_materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs/:program_id/question_banks(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_program_question_banks_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"question_banks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_question_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_question_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/question_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_question_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"question_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_questions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staff/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/staffer_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_search_admin_subscribers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[6,"staffer_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffer/units/:unit_id/materials(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffer_unit_materials_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"materials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffers/check_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffers_check_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"check_email"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffers/weekly_report_unsubscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffers_weekly_report_unsubscribe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffers"],[2,[7,"/"],[2,[6,"weekly_report_unsubscribe"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/games/state_capitals(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const state_capitals_shared_program_games_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"games"],[2,[7,"/"],[2,[6,"state_capitals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/correlations/state(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const state_shared_program_correlations_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"correlations"],[2,[7,"/"],[2,[6,"state"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/states(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const states_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/investigations/stream(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stream_shared_program_investigations_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"stream"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/investigations/stream(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stream_shared_program_lesson_investigations_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"stream"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/classes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/classes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_classrooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/lessons/:lesson_id/challenge_scores(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_lesson_challenge_scores_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"challenge_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/settings/student_management(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_management_teacher_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"student_management"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/masquerade/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_masquerade_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"masquerade"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/programs/:program_id/lessons/:lesson_id/scores(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_program_lesson_scores_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/programs/:program_id/settings(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_program_settings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /student/sections/:section_id/student_notes(.:format)
 * @param {any} section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_section_student_notes_path = __jsr.r({"section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"sections"],[2,[7,"/"],[2,[3,"section_id"],[2,[7,"/"],[2,[6,"student_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:id/student_sign_in_instructions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_sign_in_instructions_teacher_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"student_sign_in_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/student_inboxes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_student_inbox_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"student_inboxes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /student/student_inboxes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const student_student_inboxes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"student_inboxes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfer_teachers/subscriber_lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscriber_lookup_admin_transfer_teachers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfer_teachers"],[2,[7,"/"],[2,[6,"subscriber_lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:id/support(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_teacher_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/sync_classroom_scores(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sync_classroom_scores_shared_program_lesson_assessment_scores_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[6,"sync_classroom_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/notebook_scores/:id/sync_classroom_scores(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sync_classroom_scores_teacher_notebook_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"notebook_scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sync_classroom_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/slides/:id/tags(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tags_shared_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/tags(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tags_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/lessons/:lesson_id/assessments/tci(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tci_staffer_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"tci"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/teach_mode(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teach_mode_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"teach_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/assessments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/assessments/:assessment_id/assessment_page_breaks(.:format)
 * @param {any} assessment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_assessment_page_breaks_path = __jsr.r({"assessment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"assessment_id"],[2,[7,"/"],[2,[6,"assessment_page_breaks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/assessment_trends/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_assessment_trend_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"assessment_trends"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/challenge_scores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_challenge_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"challenge_scores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_classroom_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/:id(.:format)
 * @param {any} classroom_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_classroom_student_path = __jsr.r({"classroom_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/teacher_classrooms(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_classrooms_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"teacher_classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/classes/teacher_classrooms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_classrooms_student_classrooms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[6,"teacher_classrooms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/notebook_scores/:id/teacher_comment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_comment_teacher_notebook_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"notebook_scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teacher_comment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/:id/edit(.:format)
 * @param {any} classroom_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_edit_classroom_student_path = __jsr.r({"classroom_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/assessments(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_assessments_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/differentiating_instructions(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_differentiating_instructions_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"differentiating_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/enhancing_learnings(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_enhancing_learnings_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"enhancing_learnings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/presentations(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_presentations_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"presentations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/procedures(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_procedures_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"procedures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/staffer_files(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_staffer_files_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"staffer_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/lessons/:lesson_id/student_handouts(.:format)
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_lesson_student_handouts_path = __jsr.r({"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"student_handouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/zipcode_lookup/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_mdr_lookup_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"zipcode_lookup"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/notebook_scores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_notebook_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"notebook_scores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/procedures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_procedure_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"procedures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/assignments(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_assignments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/classes(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_classrooms_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/import_students(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_import_students_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"import_students"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/messages(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_messages_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/pacings(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_pacings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"pacings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/professional_developments(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_professional_developments_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"professional_developments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/program_resources(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_program_resources_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"program_resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/scores(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_scores_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/settings(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_settings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/shopping_lists(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_shopping_lists_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"shopping_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/:program_id/update_toc_settings(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_program_update_toc_settings_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"update_toc_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/reading_challenge_trends/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_reading_challenge_trend_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"reading_challenge_trends"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/science_backgrounds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_science_background_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"science_backgrounds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/scores/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_score_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"scores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/shopping_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_shopping_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"shopping_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/shopping_list_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_shopping_list_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"shopping_list_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/:code(.:format)
 * @param {any} code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_signup_path = __jsr.r({"code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[3,"code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /teacher/staffer_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_staffer_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"staffer_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/teachers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_teacher_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/teacher_seats/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_teacher_seat_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"teacher_seats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/:id(.:format)
 * @param {any} classroom_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const teacher_update_classroom_student_path = __jsr.r({"classroom_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /terms_of_service/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_service_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"terms_of_service"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/toggle(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/toggle_animations(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_animations_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_animations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/toggle_audio(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_audio_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_audio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/toggle_main_ideas(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_main_ideas_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_main_ideas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/toggle_reading_level_a(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_reading_level_a_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_reading_level_a"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/toggle_reading_level_b(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_reading_level_b_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_reading_level_b"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/classes/:classroom_id/students/toggle_spanish(.:format)
 * @param {any} classroom_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_spanish_teacher_classroom_students_path = __jsr.r({"classroom_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"classes"],[2,[7,"/"],[2,[3,"classroom_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_spanish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/students/toggle_student_seats_lock(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_student_seats_lock_admin_subscriber_students_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"students"],[2,[7,"/"],[2,[6,"toggle_student_seats_lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/subscribers/:subscriber_id/teachers/toggle_teacher_seats_lock(.:format)
 * @param {any} subscriber_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_teacher_seats_lock_admin_subscriber_teachers_path = __jsr.r({"subscriber_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"subscribers"],[2,[7,"/"],[2,[3,"subscriber_id"],[2,[7,"/"],[2,[6,"teachers"],[2,[7,"/"],[2,[6,"toggle_teacher_seats_lock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slides/:id/toggle_visibility(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_visibility_admin_slide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_visibility"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/slide_shows/:id/toggle_visibility(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_visibility_admin_slide_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_visibility"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /transfer_customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transfer_customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_parents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_parent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transfer_parents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_customers/transfer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_transfer_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_customers"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_parents/transfer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_transfer_parents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_parents"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_trials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_trial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transfer_trials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /transfer_trials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_trials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transfer_trials"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/translations/translate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const translate_admin_translations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"translations"],[2,[7,"/"],[2,[6,"translate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/trial_info_box_closed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trial_info_box_closed_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"trial_info_box_closed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/trial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trial_staffer_assessments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[6,"trial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /teacher/programs/trials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trials_teacher_programs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"teacher"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[6,"trials"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/questions/:id/ungroup(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ungroup_staffer_question_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"questions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ungroup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notebooks/unit_index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unit_index_admin_notebooks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notebooks"],[2,[7,"/"],[2,[6,"unit_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/units/:unit_id/slide_shows/unit_index(.:format)
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unit_index_admin_unit_slide_shows_path = __jsr.r({"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[6,"unit_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/investigations/unit_projects_pdf(.:format)
 * @param {any} program_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unit_projects_pdf_shared_program_investigations_path = __jsr.r({"program_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"unit_projects_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/investigations/unit_projects_pdf(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unit_projects_pdf_shared_program_lesson_investigations_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"investigations"],[2,[7,"/"],[2,[6,"unit_projects_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /student/programs/:program_id/units/:unit_id/scores/unit_scores(.:format)
 * @param {any} program_id
 * @param {any} unit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unit_scores_student_program_unit_scores_path = __jsr.r({"program_id":{"r":true},"unit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"student"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"units"],[2,[7,"/"],[2,[3,"unit_id"],[2,[7,"/"],[2,[6,"scores"],[2,[7,"/"],[2,[6,"unit_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /unlisted_host(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlisted_host_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unlisted_host"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/unpublish_class_scores(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpublish_class_scores_shared_program_lesson_assessment_scores_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[6,"unpublish_class_scores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/assessment_scores/:id/unpublish_grade(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unpublish_grade_shared_program_lesson_assessment_score_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"assessment_scores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unpublish_grade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cleanup/unselect_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unselect_message_admin_cleanup_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cleanup"],[2,[7,"/"],[2,[6,"unselect_message"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/unshare(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unshare_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unshare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staffer/assessments/:id/unshare(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unshare_staffer_assessment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffer"],[2,[7,"/"],[2,[6,"assessments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unshare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/programs/:program_id/lessons/:lesson_id/slide_shows/:id/update_slides(.:format)
 * @param {any} program_id
 * @param {any} lesson_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_slides_shared_program_lesson_slide_show_path = __jsr.r({"program_id":{"r":true},"lesson_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"programs"],[2,[7,"/"],[2,[3,"program_id"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"lesson_id"],[2,[7,"/"],[2,[6,"slide_shows"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_slides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/lessons/:id/update_tags(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_tags_admin_lesson_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"lessons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

