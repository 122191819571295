define(['ExternalRedirect'], function(ExternalRedirect) {
  function Modal(config) {
    this.trigger_element = config.trigger_element;
    this.popup_element = config.popup_element;
    this.element_to_focus = config.element_to_focus || null;
    this.initialized = false;
    this.default_closed = config.default_closed || false;
    this.position = config.position || 'fixed';
    this.popup_class = config.popup_class || 'modal_popup_wrapper';
    this.overlay = config.overlay || false;
    this.close_button_class = config.close_button_class;
    this.custom_close_selector = config.custom_close_selector || false;
    this.open = config.open || function() { this.show(); };
    this.close = config.close || function() {
      if (window.speechstream) window.speechstream.speechTools.stop();

      this.hide();
    };
    this.center = config.center || false;
    this.width = config.width;
    this.fullScreen = config.fullScreen;
    this.title = config.title || false;
    this.title_class = config.title_class;
    this.footer_contents = config.footer_contents || false;
    this.z_index = config.z_index || false;
    this.no_observer = config.no_observer || false;
  }

  Modal.prototype = {
    init: function() {
      const self = this;

      this.wrapper_element = document.createElement('div');

      // Make the wrapper element the parent of the modal content:
      this.popup_element.parentElement.insertBefore(this.wrapper_element, this.popup_element);
      this.wrapper_element.appendChild(this.popup_element);

      if (this.overlay) {
        this.overlayBackground = document.createElement('div');
        this.overlayBackground.classList.add('overlay_background');

        // Make the overlay the parent of the wrapper element:
        this.wrapper_element.parentElement.insertBefore(this.overlayBackground, this.wrapper_element);
        this.overlayBackground.appendChild(this.wrapper_element);
      }

      $(this.wrapper_element).hide();
      this.wrapper_element.style.position = this.position;
      $(this.wrapper_element).addClass(this.popup_class);
      $(this.wrapper_element).attr('tabindex', '-1');
      $(this.wrapper_element).css('z-index', this.z_index);

      const observerConfig = { attributes: true };

      const callback = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type !== 'attributes' || mutation.attributeName !== 'class') return;

          const oldClassName = mutation.oldValue || '';
          const newClassName = mutation.target.className;

          if (!oldClassName.includes('ReactModal__Body--open') && newClassName.includes('ReactModal__Body--open')) {
            if ($(self.popup_element).is(':visible')) self.close();
          }
        });
      };

      if (!this.no_observer) {
        const observer = new MutationObserver(callback);
        observer.observe(document.body, observerConfig);
      }

      if (this.center) {
        $(this.wrapper_element).addClass('center_div');
      }

      if (this.width) {
        $(this.wrapper_element).css({
          width: this.width
        });
      }

      if (this.fullScreen) {
        $(this.wrapper_element).css({
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          width: '100%'
        });
      }

      if (this.title) {
        this.makeHeader();
      }
      else {
        this.makeCloseButton();
      }

      if (this.footer_contents) {
        const footer = $(`<footer>${this.footer_contents.outerHTML}</footer>`)[0];
        $(this.wrapper_element).append(footer);
      }

      // setup listeners
      $(this.close_button).on('click', () => {
        self.close();
      });

      $(this.trigger_element).on({
        click: function(e) {
          e.preventDefault();
          self.open();
        },
        keypress: function(e) {
          const key = e.which || e.keyCode || 0;
          if (key === 13) {
            self.open();
          }
        }
      });

      // check for any close buttons in the modal and activate them
      if (this.custom_close_selector) {
        const $close_button = $(this.wrapper_element).find(this.custom_close_selector);
        if ($close_button.length > 0) {
          $($close_button).on('click', function() {
            self.close();
          });
        }
      }

      $(document).on('keyup', function(e) {
        if (e.keyCode === 27) {
          // don't try to close modal if not currently visible
          if ($(self.popup_element).is(':visible')) self.close();
        }
      });

      if (this.overlay) {
        $(this.overlayBackground).on('click', (e) => {
          if (e.target === this.overlayBackground) {
            self.close();
          }
        });
      }

      this.initialized = true;

      if (!this.default_closed) {
        this.open();
      }

      ExternalRedirect.redirectLinks(this.wrapper_element);
    },
    makeCloseButton: function() {
      this.close_button = document.createElement('button');
      this.close_button.className = this.close_button_class || 'close';
      this.close_button.innerText = '✕';

      this.close_button.setAttribute('type', 'button');
      this.close_button.setAttribute('tabindex', '0');
      this.close_button.setAttribute('aria-label', 'Close popup');

      if (this.title_wrapper) {
        this.title_wrapper.appendChild(this.close_button);
      }
      else {
        if (this.close_button_class !== 'resource_close_button') {
          this.close_button.classList.add('close-btn-pos');
        }
        this.wrapper_element.appendChild(this.close_button);
      }
    },

    makeHeader: function() {
      this.title_wrapper = document.createElement('header');
      this.title_text = document.createElement('h1');
      this.title_text.innerText = this.title;

      this.makeCloseButton();
      this.title_wrapper.appendChild(this.title_text);
      this.popup_element.parentElement.insertBefore(this.title_wrapper, this.popup_element);

      if (this.title_class) {
        this.title_wrapper.classList.add(this.title_class);
      }
    },

    show: function() {
      if (!$(this.trigger_element).hasClass('disabled')) {
        $(this.wrapper_element).show();
        $(this.wrapper_element).addClass('modal-active');
        $(this.popup_element).show();
        this.containFocus();
        $('body').addClass('block-scroll');
        if (this.overlay) {
          $(this.overlayBackground).addClass('visible');
        }

        // focuses on element_to)_focus if passed, otherwise focuses on modal by default
        if (this.element_to_focus) {
          document.querySelector(`.modal-active .${this.element_to_focus.className}`).focus();
        }
        else {
          document.querySelector('.modal-active').focus({ preventScroll: true });
        }

        if ($(this.trigger_element).data('redraw-content')) {
          window.dispatchEvent(new Event('resize'));
        }
      }
    },

    hide: function() {
      const $modal = $('.modal-active');
      const $video = $modal.find('video');
      if ($video.length > 0) $video.trigger('pause');
      $modal.hide();
      $modal.removeClass('modal-active');
      if (this.overlay) {
        // hide all modal overlays once one is closed
        $(document).find('.overlay_background.visible').each((index, overlay) => {
          $(overlay).removeClass('visible');
        });
        $('body').css('overflow', 'auto');
        $(document).off('focusin');
      }

      if (this.trigger_element && !$(this.trigger_element).hasClass('question-bank-preview')) {
        this.trigger_element.focus({ preventScroll: true });
      }
    },

    containFocus: function() {
      $(document)
        .off('focusin')
        .on('focusin', (event) => {
          if (document !== event.target &&
            document.querySelector('.modal-active') !== event.target &&
            $(document.querySelector('.modal-active')).has(event.target).length === 0 &&
            !event.target.classList.contains('fr-link-attr') &&
            $(event.target).attr('data-fabric-hiddentextarea') === undefined
          ) {
            document.querySelector('.modal-active').focus({ preventScroll: true });
          }
        });
    }
  };

  return Modal;
});
