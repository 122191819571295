import * as Routes from 'routes';

const LINK_REGEX = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

const CDN_REGEX = /cdn\w+.teachtci.com/;
const S3_LINK = /plato\w+.s3/;

const SUBDOMAINS = [
  'dev-subscriptions',
  'dev-student',
  'demo-subscriptions',
  'demo-student',
  'staging-subscriptions',
  'staging-student',
  'subscriptions',
  'student'
];

const WHITELISTED_DOMAINS = [
  'www.teachtci.com/terms.html',
  'www.teachtci.com/privacy-policy.html'
];

export const redirectLinks = (container) => {
  if (!document.body.classList.contains('external-redirect-enabled')) return;

  container.querySelectorAll('a').forEach((link) => {
    if (link.dataset.convertedExternal) return;
    if (link.classList.contains('safe-link')) return;

    const url = link.href || '';

    const isValidUrl = url.match(LINK_REGEX);
    const isNGSSCorrelationsLink = url.includes('/ngss_standards/correlations');
    // add the redirect notice on NGSS correlations page even though it's in under the subscriptions domain
    if (!isValidUrl && !isNGSSCorrelationsLink) return;

    const isCdnLink = url.match(CDN_REGEX) || url.match(S3_LINK);
    if (isCdnLink) return;

    const isWhitelisted = WHITELISTED_DOMAINS.some(domain => url.includes(domain));
    if (isWhitelisted) return;

    const isSubscriptionLink = SUBDOMAINS.some(domain => url.includes(`${domain}.teachtci.com`));
    if (isSubscriptionLink && !isNGSSCorrelationsLink) return;

    link.href = Routes.external_path({ prevUrl: window.location.href, url });
    link.dataset.convertedExternal = true;
  });
};
